<template>
	<router-view></router-view>
</template>

<script>
	export default {
		name: 'App',
		components: {

		}
	}
</script>

<style>
	/**自定义上传按钮**/
	.upbutton {
		width: 80px;
		height: 103px;
		position: relative;
		display: block;
		overflow: hidden;
	}

	.upbutton span {
		font-size: 12px;
	}

	.upbutton input {
		width: 80px;
		height: 88px;
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
		cursor: pointer;
		font-size: 200px !important;
	}

	.upbutton a.update {
		font-size: 14px;
		/*background: url(../images/ib_icon12.png) no-repeat left center;*/
		width: 80px;
		height: 88px;
		text-align: center;
		line-height: 28px !important;
		color: #3d3d3d;
		display: block;
		text-decoration: none;
	}
</style>