import {
	createRouter,
	createWebHashHistory
} from 'vue-router';
const router = createRouter({
	history: createWebHashHistory(),
	routes: [{
			path: '/',
			redirect: '/login',
		},
		{
			path: '/dashboard',
			component: () => import('../views/Dashboard'),
			meta: {
				isAuth: true,
			},
		},
		{
			path: '/manager/manager',
			component: () => import('../views/Manager/Manager'),
			meta: {
				isAuth: true,
			},
		},
		{
			path: '/info/info',
			component: () => import('../views/Info/Info'),
			meta: {
				isAuth: true,
			},
		},
		{
			path: '/info/category',
			component: () => import('../views/Info/Category'),
			meta: {
				isAuth: true,
			},
		},
		{
			path: '/info/comment',
			component: () => import('../views/Info/Comment'),
			meta: {
				isAuth: true,
			},
		},
		{
			path: '/manager/password',
			component: () => import('../views/Manager/Password'),
			meta: {
				isAuth: true,
			},
		},
		{
			path: '/login',
			component: () => import('../views/User/Login'),
		},
		{
			path: '/user/user_score',
			component: () => import('../views/User/User_Score'),
		},
		{
			path: '/user/user_sub',
			component: () => import('../views/User/User_Sub'),
		},
		{
			path: '/feedback/feedback',
			component: () => import('../views/Feedback/Feedback'),
			meta: {
				isAuth: true,
			},
		},
		{
			path: '/qna/qna',
			component: () => import('../views/Qna/Qna'),
			meta: {
				isAuth: true,
			},
		},
		{
			path: '/qna/qna_edit',
			component: () => import('../views/Qna/Qna_Edit'),
			meta: {
				isAuth: true,
			},
		},
		{
			path: '/news/news',
			component: () => import('../views/News/News'),
			meta: {
				isAuth: true,
			},
		},
		{
			path: '/news/news_edit',
			component: () => import('../views/News/News_Edit'),
			meta: {
				isAuth: true,
			},
		},
		{
			path: '/news/category',
			component: () => import('../views/News/Category'),
			meta: {
				isAuth: true,
			},
		}, {
			path: '/ad/ad',
			component: () => import('../views/Ad/Ad'),
			meta: {
				isAuth: true,
			},
		},
		{
			path: '/ad/ad_edit',
			component: () => import('../views/Ad/Ad_Edit'),
			meta: {
				isAuth: true,
			},
		},
		{
			path: '/user/user',
			component: () => import('../views/User/User'),
			meta: {
				isAuth: true,
			},
		},
		{
			path: '/withdraw/withdraw',
			component: () => import('../views/Withdraw/Withdraw'),
			meta: {
				isAuth: true,
			},
		},
		{
			path: '/income/income',
			component: () => import('../views/Income/Income'),
			meta: {
				isAuth: true,
			},
		},
		{
			path: '/payment/payment',
			component: () => import('../views/Payment/Payment'),
			meta: {
				isAuth: true,
			},
		},
		{
			path: '/payment/vip',
			component: () => import('../views/Payment/Vip'),
			meta: {
				isAuth: true,
			},
		},
		{
			path: '/payment/info',
			component: () => import('../views/Payment/Info'),
			meta: {
				isAuth: true,
			},
		},
		{
			path: '/system/system',
			component: () => import('../views/System/System'),
			meta: {
				isAuth: true,
			},
		},
		{
			path: '/vip/vip',
			component: () => import('../views/Vip/Vip'),
			meta: {
				isAuth: true,
			},
		},
	],
});
// 路由拦截
router.beforeEach((to, from, next) => {
	//判断是否需要登录权限
	if (to.meta.isAuth) {
		if (localStorage.getItem('token')) {
			next();
		} else {
			ElMessage.error({
				message: '请先登录',
				type: 'error',
			});
			router.push('./login');
		}
	} else {
		next();
	}
});
export default router;