import {
	createApp
} from 'vue'
import App from './App.vue'
import './common/base.css';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import * as elementIcons from '@element-plus/icons-vue';
import router from './router/index';

function getLoginUser() {
	if (localStorage.getItem("user") == null) {
		return null;
	} else {
		return JSON.parse(localStorage.getItem("user"));
	}
}

function getToken() {
	if (localStorage.getItem("token") == null) {
		return "";
	} else {
		return localStorage.getItem("token");
	}
}

//解决ResizeObserver loop limit exceeded 问题开始
const debounce = (fn, delay) => {
	let timer = null;
	return function() {
		let context = this;
		let args = arguments;
		clearTimeout(timer);
		timer = setTimeout(function() {
			fn.apply(context, args);
		}, delay);
	}
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
	constructor(callback) {
		callback = debounce(callback, 16);
		super(callback);
	}
}
//解决ResizeObserver loop limit exceeded 问题结束

const app = createApp(App)
const base_url = "https://maiya-api.webhh.net/api/v1/admin/";
//const base_url = "http://127.0.0.1:8088/api/v1/admin/";
//const base_url = "https://temple-web.funyog.com/api/v1/admin/";
const img_url = "https://temple-web.funyog.com/files/";
app.use(ElementPlus, {
  locale: zhCn,
}) //开启了按需导入 注释掉此行
app.use(router)
for (let iconName in elementIcons) {
	app.component(iconName, elementIcons[iconName]);
}

app.config.globalProperties.base_url = base_url;
app.config.globalProperties.img_url = img_url;
app.config.globalProperties.getLoginUser = getLoginUser;
app.config.globalProperties.getToken = getToken;
app.mount('#app')
